import { toArray } from "gsap";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

if(typeof gsap !== 'undefined') {


    // Set GSAP default vars
    gsap.defaults({
        ease: "power3.easeInOut"
    })

    // Set Scrolltrigger defaults
    ScrollTrigger.defaults({
        scrub:true
    })


    const scrollContainer = Array.from(document.querySelectorAll('.sk_parallax_bg_wrapper'))

    /**
     * Scroll Effect for block "sk-parallax-background"
     */

    scrollContainer.forEach((el, key, arr) => {
        gsap.fromTo(
            '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(1)',
            {
                y: "-5vw"
            },
            {
                yPercent: 6,
                scrollTrigger: {
                    trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                    start: 'top bottom',
                }
            }
        )
    
        gsap.fromTo(
            '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(2)',
            {
                y: "-7vw"
            },
            {
                yPercent: 10,
                scrollTrigger: {
                    trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                    start: 'top bottom',
                }
            }
        )
    
        gsap.fromTo(
            '#' + el.id.toString() + ' .sk_parallax_bg_el:nth-child(3)',
            {
                y: "-6vw"
            },
            {
                yPercent: 8,
                scrollTrigger: {
                    trigger: '.wp-block-sk-pharma-sk-parallax-background:has(#' + el.id.toString() + ')',
                    start: 'top bottom',
                }
            }
        )
    });
}